/* eslint jsx-a11y/no-noninteractive-element-interactions: 0 */
/* eslint jsx-a11y/no-noninteractive-tabindex: 0 */

import React, { useState } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { animated, useSpring, useSprings } from "react-spring"
import { transparentize } from "polished"

import ArrowIcon from "./../assets/icons/up-arrow.svg"
import Spacer from "./Spacer"
import { breakpoints } from "../assets/globalStyles"

const StyledMobileCategories = styled.div`
  @media screen and (min-width: ${breakpoints.lg}px) {
    display: none;
  }
  .select {
    position: relative;
    outline: none !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border: 1px solid #ced4db;
    border-radius: 4px;
    padding: 12px 24px;
    color: #1c1c26;
    font-family: Roboto;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 32px;
    z-index: 2;
    cursor: pointer;
    background-color: #ffffff;
    img {
      opacity: 0.3;
    }
  }

  .dropdown {
    overflow: hidden;
    z-index: 1;
    position: absolute;
    background-color: #ffffff;
    border: 1px solid #ced4db;
    border-radius: 4px;
    padding: 12px 0;
    left: 0;
    right: 0;
    margin: 0 15px;
    display: flex;
    flex-direction: column;
    a {
      color: #1c1c26;
      margin: 4px 0px;
      display: block;
      padding: 8px 24px;
      font-family: Roboto;
      font-size: 18px;
      letter-spacing: 0;
      line-height: 28px;
      cursor: pointer;
      &.active {
        background-color: ${transparentize(0.85, "#66768e")};
        pointer-events: none;
      }
    }
  }
`

const StyledDesktopCategories = styled.div`
  display: none;
  @media screen and (min-width: ${breakpoints.lg}px) {
    display: flex;
  }
`

const Bar = styled(animated.div)`
  background-color: #d42b69;
  height: 4px;
`

const Wrapper = styled(Link)`
  color: #1c1c26;
  font-family: Poppins;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 40px;
  margin-right: 32px;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-decoration: none;
  &:hover {
    color: #1c1c26;
  }
`

export default function CategoryTabs({ currentCategory, categories }) {
  const [showDropdown, setShowDropdown] = useState(false)

  const dropdownAnimation = useSpring({
    maxHeight: showDropdown ? "250px" : "0px",
    transform: showDropdown ? "translateY(0px)" : "translateY(-34px)",
    config: {
      tension: 200,
      friction: 25,
      clamp: true,
    },
  })

  const iconAnimation = useSpring({
    transform: showDropdown ? "rotate(0deg)" : "rotate(180deg)",
    config: {
      tension: 200,
      friction: 25,
      clamp: true,
    },
  })

  const [hoveredDesktopIndex, setHoveredDesktopIndex] = useState()

  const desktopCategoriesAnimations = useSprings(
    categories.length,
    categories.map(({ name }, index) => ({
      width:
        hoveredDesktopIndex === index || currentCategory.name === name
          ? "100%"
          : "0%",
      config: {
        clamp: true,
        friction: 12,
      },
    }))
  )

  //combine animation properties with menu items
  const animatedCategoryItems = categories.map((category, index) => ({
    ...category,
    animationProps: desktopCategoriesAnimations[index],
  }))

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <StyledMobileCategories>
            <div
              className="select"
              onClick={() => {
                setShowDropdown(!showDropdown)
              }}
              onKeyDown={() => {
                setShowDropdown(!showDropdown)
              }}
              tabIndex={0}
              role="navigation"
            >
              {currentCategory.name}
              <animated.img src={ArrowIcon} style={iconAnimation} alt="" />
            </div>
            <Spacer size={8} />

            <animated.div className="dropdown" style={dropdownAnimation}>
              {categories.map(({ slug, name }) => (
                <Link
                  to={`${slug}/`}
                  key={slug}
                  className={currentCategory.name === name ? "active" : ""}
                >
                  {name}
                </Link>
              ))}
            </animated.div>
          </StyledMobileCategories>
          <StyledDesktopCategories>
            {animatedCategoryItems.map(
              ({ slug, name, animationProps }, index) => (
                <Wrapper
                  onMouseEnter={() => {
                    setHoveredDesktopIndex(index)
                  }}
                  onMouseLeave={() => {
                    setHoveredDesktopIndex()
                  }}
                  key={slug}
                  to={`${slug}/`}
                >
                  {name}
                  <Bar style={animationProps} />
                </Wrapper>
              )
            )}
          </StyledDesktopCategories>
        </div>
      </div>
    </div>
  )
}
