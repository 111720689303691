import React from "react"
import { graphql } from "gatsby"

import Seo from "../components/Seo"
import CallToAction from "../components/CallToAction"
import PageHeader from "../components/PageHeader"
import CategoryTabs from "../components/CategoryTabs"
import Pagination from "../components/Pagination"
import Spacer from "../components/Spacer"
import BlogPostBox from "../components/BlogPostBox"

export const categoryData = graphql`
  query($postIds: [String]) {
    allWpPost(
      filter: { id: { in: $postIds } }
      sort: { order: DESC, fields: date }
    ) {
      nodes {
        id
        title
        slug
        date(formatString: "DD/MM/YYYY")
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
              }
              publicURL
            }
            mimeType
          }
        }
        author {
          node {
            firstName
            lastName
          }
        }
        categories {
          nodes {
            name
          }
        }
      }
    }
    wpPage(title: { eq: "Blog" }) {
      pageHeaderFields {
        headerContent
      }
      seo {
        metaDesc
        title
        opengraphImage {
          localFile {
            publicURL
          }
        }
      }
      featuredImage {
        node {
          localFile {
            publicURL
          }
        }
      }
      callToActionFields {
        callToAction {
          button {
            label
            link {
              url
            }
          }
          content
        }
      }
    }
  }
`
export default function Category({
  data: {
    allWpPost: { nodes: allBlogPosts },
    wpPage: {
      seo,
      featuredImage,
      pageHeaderFields: { headerContent },
      callToActionFields: { callToAction },
    },
  },
  pageContext: { totalPages, currentPage, currentCategory, categories },
}) {
  const blogPosts = allBlogPosts.map(
    ({
      author: {
        node: { firstName, lastName },
      },
      date,
      id,
      slug,
      title,
      featuredImage: { node: imageData },
      categories: { nodes: categoryArray },
    }) => ({
      author: `${firstName} ${lastName}`,
      date,
      id,
      slug,
      title,
      featuredImage: {
        imageType: imageData.mimeType,
        imageUrl: imageData.localFile?.publicURL,
        gatsbyImageData: imageData.localFile?.childImageSharp?.gatsbyImageData,
      },
      categories: categoryArray.map(({ name }) => name),
    })
  )

  return (
    <>
      <Seo
        seo={{
          ...seo,
          featuredImage: featuredImage?.node?.localFile?.publicURL,
        }}
      />
      <PageHeader headerContent={headerContent} showNewsletter={true} />
      <Spacer size={32} when={{ md: 48 }} />
      <CategoryTabs currentCategory={currentCategory} categories={categories} />
      <Spacer size={24} when={{ md: 40 }} />
      <div className="container">
        <div className="row">
          {blogPosts.map(({ id, ...blogPostDetails }) => (
            <div className="col-12 col-md-6" key={id}>
              <BlogPostBox blogPost={blogPostDetails} />
              <Spacer size={24} />
            </div>
          ))}
        </div>
        {totalPages > 1 && (
          <div className="row">
            <div className="col-12">
              <Pagination
                prevTo={
                  currentPage === 2
                    ? `${currentCategory.slug}/`
                    : `${currentCategory.slug}/${currentPage - 1}/`
                }
                prevActive={currentPage !== 1 ? 1 : 0}
                nextTo={`${currentCategory.slug}/${currentPage + 1}/`}
                nextActive={currentPage !== totalPages ? 1 : 0}
              />
            </div>
          </div>
        )}
      </div>
      <Spacer size={40} when={{ md: 56 }} />
      <CallToAction content={callToAction} />
      <Spacer size={40} when={{ md: 56 }} />
    </>
  )
}
